<template>
  <auth-content v-if="user">
    <el-header slot="header" :title="$t('titles.profile_title', {name: user.full_name ? user.full_name : user.email})" :favorites="false">
      <div slot="left">
        <el-header-back />
      </div>
    </el-header>


    <div class="row">
      <div class="col-lg-5 col-xxl-3">
        <div class="card">
          <div class="card-body text-center">
            <el-avatar v-if="user.avatar.thumb" :src="user.avatar.full" size="big"></el-avatar>
            <h4 class="mt-3">{{ user.full_name }}</h4>
            <h5 class="my-2 font-weight-bold" v-if="user.position">{{ user.position }}</h5>
            <div v-if="user.metadata && user.metadata.social && user.metadata.social.split(/\n/).length > 0" class="border-top pt-2 mt-3">
              <a target="_new" :key="socialLink" v-for="socialLink in user.metadata.social.split(/\n/)" class="m-1 circle d-inline-block bg-info text-white socialLink text-center" :href="socialLink"><i :class="socialIcon(socialLink)"></i></a>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-7 col-xxl-9">
        <div class="card">
          <div class="card-body">
            <el-alert type="info" v-if="userProfileWorld.pivot.owner">
              {{ $t('form.user.profile_is_owner', {name: user.full_name ? user.full_name : user.email}) }}
            </el-alert>
            <div v-if="user.tribes.length > 0">
              <fragment v-for="tribe in user.tribes" :key="tribe.id">
                <router-link tag="div" :to="{name: 'world.tribe', params: { world: $route.params.world, tribe: tribe.id }}" class="d-inline-block mr-3 mb-3 font-weight-bold cursor-pointer">
                  <el-avatar v-if="tribe.avatar && tribe.avatar.thumb" :src="tribe.avatar.thumb"/>
                  <el-avatar v-else-if="tribe.slug" :src="require('@/assets/images/categories/' + tribe.slug + '.svg')"/>
                  <h5 class="d-inline-block ml-2">{{ tribe.name }}</h5>
                </router-link>
              </fragment>
            </div>
            <div class="pb-2 mb-2 border-bottom" v-if="user.email">
              <label class="mb-1 font-weight-bold">{{ $t('form.user.email') }}</label>
              <div>{{ user.email }}</div>
            </div>
            <div class="pb-2 mb-2 border-bottom" v-if="user.phone_number">
              <label class="mb-1 font-weight-bold">{{ $t('form.user.phone_number') }}</label>
              <div>{{ user.phone_number }}</div>
            </div>
            <div class="pb-2 mb-2 border-bottom" v-if="user.birthday">
              <label class="mb-1 font-weight-bold">{{ $t('form.user.birthday') }}</label>
              <div>{{ user.birthday }}</div>
            </div>
            <div class="pb-2 mb-2 border-bottom" v-if="user.metadata && user.metadata.carreer">
              <label class="mb-1 font-weight-bold">{{ $t('form.user.carreer') }}</label>
              <div>{{ user.metadata.carreer }}</div>
            </div>
            <div class="pb-2 mb-2 border-bottom" v-if="user.metadata && user.metadata.bio">
              <label class="mb-1 font-weight-bold">{{ $t('form.user.bio') }}</label>
              <div>{{ user.metadata.bio }}</div>
            </div>
            <div class="pb-2 mb-2 border-bottom" v-if="user.metadata && user.metadata.quote">
              <label class="mb-1 font-weight-bold">{{ $t('form.user.quote') }}</label>
              <div>{{ user.metadata.quote }}</div>
            </div>
            <div class="pb-2 mb-2 border-bottom" v-if="user.metadata && user.metadata.expertise && user.metadata.expertise.length > 0">
              <label class="mb-1 font-weight-bold">{{ $t('form.user.expertise') }}</label>
              <div>{{ user.metadata.expertise }}</div>
            </div>
            <a target="_new" class="btn btn-outline-primary mr-2 mt-1" :href="'mailto:' + user.email">
              <i class="icon-mail5 mr-1"></i>{{ $t('form.user.profile_send_mail', {name: user.first_name ? user.first_name : user.email}) }}
            </a>
            <a v-if="user.phone_number" target="_new" class="btn btn-outline-primary mr-2 mt-1" :href="'tel:' + user.phone_number">
              <i class="icon-phone2 mr-1"></i>{{ $t('form.user.profile_call', {name: user.first_name ? user.first_name : user.phone_number}) }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </auth-content>
</template>

<script>

import store from '../../store'
import { WORLD_USER_REQUEST } from '../../store/mutations-types'

export default {
  name: 'UserProfileForm',
  data () {
    return {
      user: false,
    }
  },
  beforeRouteEnter (to, from, next) {
    store.dispatch('world/' + WORLD_USER_REQUEST, {worldId: to.params.world, userId: to.params.user}).then((user) => {
      next(vm => {
        vm.user = user
      })
    })
  },
  methods: {
    socialIcon (link) {
      if (!link) {
        return 'icon-link'
      }
      else if (link.indexOf('facebook') !== -1) { return 'icon-facebook' }
      else if (link.indexOf('twitter') !== -1) { return 'icon-twitter' }
      else if (link.indexOf('instagram') !== -1) { return 'icon-instagram' }
      else if (link.indexOf('youtube') !== -1) { return 'icon-youtube' }
      else if (link.indexOf('vimeo') !== -1) { return 'icon-vimeo' }
      else if (link.indexOf('flickr') !== -1) { return 'icon-flickr' }
      else if (link.indexOf('dribbble') !== -1) { return 'icon-dribbble' }
      else if (link.indexOf('wordpress') !== -1) { return 'icon-wordpress' }
      else if (link.indexOf('dropbox') !== -1) { return 'icon-dropbox' }
      else if (link.indexOf('github') !== -1) { return 'icon-github' }
      else if (link.indexOf('google') !== -1) { return 'icon-google' }
      else if (link.indexOf('onedrive') !== -1) { return 'icon-onedrive' }
      else if (link.indexOf('blogger') !== -1) { return 'icon-blogger' }
      else if (link.indexOf('tumblr') !== -1) { return 'icon-tumblr' }
      else if (link.indexOf('soundcloud') !== -1) { return 'icon-soundcloud' }
      else if (link.indexOf('skype') !== -1) { return 'icon-skype' }
      else if (link.indexOf('reddit') !== -1) { return 'icon-reddit' }
      else if (link.indexOf('linkedin') !== -1) { return 'icon-linkedin2' }
      else if (link.indexOf('foursquare') !== -1) { return 'icon-foursquare' }
      else if (link.indexOf('paypal') !== -1) { return 'icon-paypal' }
      else if (link.indexOf('yelp') !== -1) { return 'icon-yelp' }
      else if (link.indexOf('bitbucket') !== -1) { return 'icon-git' }
      return 'icon-link'
    },
  },
  mounted () {
  },
  computed: {
    userProfileWorld () {
      return this.user.worlds.find(world => world.id == this.$route.params.world)
    },
  }
}
</script>

<style lang="scss" scoped>
.socialLink {
  width: 32px;
  height: 32px;
  line-height: 32px;
}
</style>
